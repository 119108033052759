header {
	@media (max-width:$screen-sm-min){
		padding-top: 0!important;
	}
	@media (min-width: $screen-sm-min) {
		.navbar-fixed-top {
			position: relative;
		}
	}
}

.search-form {
	float: right;

	.term {
		border-bottom: 1px solid #cccccc;
		border-width: 0 0 1px 0;
		box-shadow: none;
		font-style: italic;
		font-size: 15px;
		margin: 17px 0 19px;
		padding-left:0;
		width:100%;

		&:focus {
			border-color:$brand-yellow;

			+ .input-group-btn .btn {
				border-bottom:1px solid $brand-yellow;
			}
		}



		@media (min-width: $screen-md-min) {
			min-width: 170px;
		}

		@media (min-width: $screen-lg-min + 200) {
			min-width: 218px;
		}
	}
}

.navbar-default .navbar-nav {


	/* desktop nav */
	li {

		a {
			padding: 20px;
			font-weight: 600;
			border-bottom: 5px solid #fff;
			color: $brand-purple;
			font-size:0.8em; // 16px

			@media (min-width: $screen-sm-min) {
				font-size:0.65em;
				padding: 25px 16px 19px;
			}

			@media (min-width: $screen-lg-min) {
				padding: 25px 16px 19px;
				font-size:0.8em; // 16px
			}
		}

		&:hover, &:focus, &:active {
			a {
				background: transparent;
				border-color: $brand-yellow;
			}
		}

		&.active, &.current-page-ancestor {
			a {
				background: $brand-light-grey;
				color: #333;
				border-bottom: 5px solid $brand-yellow;

				&:hover, &:focus {
					background: $brand-light-grey;
				}
			}
		}

		&.menu-item-has-children {
			a {
				padding:20px;

				@media (min-width: $screen-sm-min) {
					padding: 25px 10px 19px 10px;
				}

				@media (min-width: $screen-lg-min) {
					padding: 25px 14px 19px 21px;
				}

				&:after {
					margin-left:2px;
				}
			}
		}
	}
}

.headers-additional {
	padding-bottom: floor($grid-gutter-width / 2);
	height: $header-height;

	@media (min-width:$screen-sm-min){
		height: $header-desktop-height;
	}

	&.navbar-fixed-top {
		&+main, &+.advbanners {
			margin-top: $header-height;

			@media (min-width:$screen-sm-min){
				margin-top: $header-desktop-height;
			}
		}
	}

	a {

		&:hover, &:focus {
			color: $navbar-default-link-hover-color;
			background-color: transparent;
			outline:none;
		}
	}

	.logo-wrapper {
		padding: 0;
	}

	.logo {
		display: block;
		background: $header-logo no-repeat;
		background-size: contain;
		height: $header-height - 20;
		width: 50%;
		margin: 10px;
		&:hover, &:focus {
			opacity: 0.75;
			outline:none;
		}
		@media (min-width:$screen-sm-min){
			height: 65px;
			margin: 10px;
		}
		@media (min-width:$screen-md-min){
			background-position: top;
			height: 103px;
			float: none;
			width: 204px;
			margin: 0 auto;
		}
	}

	.navbar-header {
		width: 100%;

		@media (min-width:$screen-md-min) {
			padding-top: 12px;
		}

		@media (max-width:$screen-sm-min - 1){
			div[class^="col-"] {
				padding: 0;
			}
		}
	}

	.navbar-toggle {
		z-index: 1;
	}

	.navbar-collapse {
		text-align: center;
	}
}

@media(min-width: $grid-float-breakpoint) {
	.headers-additional {
		.navbar-collapse {
			text-align: left;

			&.in {
				overflow-y: inherit;
			}
		}
	}
}



.navbar-collapse {
	max-height: none !important;
}

.navbar-toggle {
	z-index: 1;
	position: absolute;
	right: 10px;
	height:45px;
	width:50px;
	background: $navbar-default-toggle-bg;
	border: 1px solid $navbar-default-toggle-border-color;
	border-radius: $navbar-default-toggle-radius;
	&:hover, &:focus {
		background: $navbar-default-toggle-hover-bg;
		outline:none;
	}
	.icon-bar {
		background: $navbar-default-toggle-icon-bar-bg;
		border-radius: $navbar-default-toggle-icon-bar-radius;
		width: 30px;
		height: 4px;
		margin-bottom: 6px;
		@include transition(all 0.2s ease);

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.active{
		.icon-bar:nth-child(2){
			@include transform(rotate(45deg));
			position:absolute;
			margin:10px 0 0;
			right:auto;
			left:auto;
			top:10px;
		}
		.icon-bar:nth-child(3){
			@include transform(rotate(-45deg));
			position:absolute;
			margin:10px 0 0;
			right:auto;
			left:auto;
			top:10px;
		}
		.icon-bar:nth-child(4){
			display:none;
		}
	}
}



.navs-flexnav {
	padding-left: 0px;
	background: #ffffff;

	ul {
		padding-left: 0px;
	}

	@include transition(none);
	-webkit-transform-style: preserve-3d; // stop webkit flicker
	overflow: hidden;
	margin: 0 auto;
	width: 100%;
	overflow-y: scroll;

	&.navs-flexnav-show {
		opacity: 1;
		@include transition(all .5s ease-in-out);
	}
	&.one-page {
		position: fixed;
		top: 50px;
		right: 5%;
		max-width: 200px;
	}

	li {
		font-size: 100%;
		position: relative;
		overflow: hidden;
		display:block;
	}
	/* level 1 */
	li a {
		position: relative;
		display: block;
		padding: .96em 30px;
		z-index: 2;
		overflow: hidden;
		text-align:$flex-align;
		color: $flex-color;
		background: $top_level;
		border-top: 1px solid #f0f0f0;
		font-size: 16px;
		&.active {
			//background: $top_level-hover;
			color: $brand-secondary !important;
			font-weight: 500;
		}
	}
	li a:hover, li a:focus {
		background: $top_level-hover;
		text-decoration:none;
		outline:none;
	}

	li ul {
		width: 100%;

		li {
			font-size: 100%;
			position: relative;
			overflow: hidden;
		}
	}
	li ul.navs-flexnav-show {

		li {
			overflow: visible;
		}
	}

	li ul li a {
		/* level 2 */
		display: block;
		background: $second_level;
		padding-left: 4*$padding-base-horizontal;

	}
	ul li ul li a {
		/* level 3 */
		background: $third_level;
		color: $third-level-text-color;
		border-top: 1px solid $border-second-level;
		padding-left: 6*$padding-base-horizontal;
	}
	ul li ul li ul li a {
		/* level 4 */
		background: $fourth_level;
		color: $fourth-level-text-color;
		padding-left: 9*$padding-base-horizontal;
	}
	.touch-button {
		position: absolute;
		z-index: 999;
		top: 1px;
		right: 0;
		width: 60px;
		height: 55px;
		display: inline-block;
		background: transparent;
		text-align: center;
		border-left:1px solid $border-top-level;

		&:hover {
			cursor: pointer;
		}
		&:after {
			content: $flex-icon-closed;
			font-family: 'FontAwesome';
			color: $flex-color;
			font-size: 20px;
			top: 0.5em;
			position: relative;
		}
		&.active:after {
			content: $flex-icon-open;
		}
		.navicon {
			display: none;
		}
	}
}

.navbar .navbar-collapse{
	text-align: left;
	box-shadow: none;

	@media (min-width: $screen-sm-min) {
		text-align: center;
	}

	> ul > li {
		margin-right:0;

		@media (min-width: $screen-lg-min + 200) {
			margin-right: 19px;
		}

		&.focussed {
			> a {
				background: $top_level-hover;
				text-decoration:none;

				background: transparent;
				border-color: $brand-yellow;
				color: $brand-yellow;
			}
		}
	}


	/* dropdowns */
	ul#menu-main-menu ul, ul#menu-coordinator-menu ul {
		position: absolute;
		z-index: 2;
		min-width: 240px;
		padding: 0;
		list-style: none;
		text-align: left;
		background-color: #f6f4f1;
		box-shadow: 0 2px 5px rgba(0,0,0,.175);
		background-clip: padding-box;
		@include opacity(0);
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		display: block;
		white-space: nowrap;
		width: 1px;

		@include transition(opacity 0.3s ease-in-out);

		li {
			a {
				font-weight: 500;
				display: block;
				padding: 10px 14px;
				margin: 0;
				border-bottom: 1px solid #888;
				border-bottom: 0;
			}
			a:hover, a:focus, &.active > a {
				background: $brand-yellow;
				text-decoration: none;
				color: #333;
				outline:none;
			}

			&.active > a {
				background: $brand-purple;
				text-decoration: none;
				color: #fff;
			}
			&:last-child a {
				border-bottom: 0;
			}
		}
	}

	ul#menu-main-menu li.menu-item-has-children > a:after, ul#menu-coordinator-menu li.menu-item-has-children > a:after {
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: " \f0d7";
		margin-left: 4px;
		color: $brand-purple;
		display:none;

		@media (min-width: $screen-sm-min) {
			display:inline-block;
		}
	}

	ul#menu-main-menu > li:hover > ul, ul#menu-coordinator-menu > li:hover > ul, ul#menu-main-menu > li.focussed > ul, ul#menu-coordinator-menu > li.focussed > ul {
		@media (min-width: $screen-sm-min) {
			@include opacity(1);
			display: block;
			clip: auto;
			height: auto;
			margin: 0;
			overflow: visible;
			white-space: normal;
			width: auto;
		}
	}

}

.coordinator-nav {
	position: absolute;
	right: 0;
	margin-right: 115px;
	border:0;
	top: 22px;
	margin-top:27px;

	z-index: 999;

	ul#menu-coordinator-menu li.menu-item-has-children.menu-coordinator-area {
		a {
			padding: 8px 12px 9px;
			border:0;
			position:relative;
			padding-right: 65px;
			font-size: 0.7em;
			background: #f4f4f4;

			&:after {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				background: #eae8e8;
				width: 48px;
				margin:0;
				color: $brand-purple;
				font-size:20px;
				vertical-align: middle;
				line-height: 46px;
				padding-right: 5px;
			}
		}

		ul {
			min-width:120px;
			box-shadow:none;

			li {
				a {
					background: #eae8e8;

					&:hover, &:focus {
						background: $brand-yellow;

					}
				}

				&.active {
					a {
						background: $brand-purple;

						&:hover, &:focus {
							background: lighten( $brand-purple, 10%);
						}
					}
				}
			}
		}
	}
}

.navbar-nav li.active a, .navbar-nav li.current-page-ancestor a {
	background-color: #f4f4f4;
	color: #333;
	border-bottom: 5px solid #f0b155;

	&:hover, &:focus {
		background-color: #f4f4f4;
	}
}
@media (min-width: $screen-sm-min) {
	div#navigationmenu {
		border-top: 1px solid #f0f0f0;
		margin-top: 30px;
	}
}
