// Glyphicons font path
$icon-font-path:        "../fonts/";

/* Sass Document */
$fa-adjust: "\f042";
$fa-adn: "\f170";
$fa-align-center: "\f037";
$fa-align-justify: "\f039";
$fa-align-left: "\f036";
$fa-align-right: "\f038";
$fa-ambulance: "\f0f9";
$fa-anchor: "\f13d";
$fa-android: "\f17b";
$fa-angellist: "\f209";
$fa-angle-double-down: "\f103";
$fa-angle-double-left: "\f100";
$fa-angle-double-right: "\f101";
$fa-angle-double-up: "\f102";
$fa-angle-down: "\f107";
$fa-angle-left: "\f104";
$fa-angle-right: "\f105";
$fa-angle-up: "\f106";
$fa-apple: "\f179";
$fa-archive: "\f187";
$fa-area-chart: "\f1fe";
$fa-arrow-circle-down: "\f0ab";
$fa-arrow-circle-left: "\f0a8";
$fa-arrow-circle-o-down: "\f01a";
$fa-arrow-circle-o-left: "\f190";
$fa-arrow-circle-o-right: "\f18e";
$fa-arrow-circle-o-up: "\f01b";
$fa-arrow-circle-right: "\f0a9";
$fa-arrow-circle-up: "\f0aa";
$fa-arrow-down: "\f063";
$fa-arrow-left: "\f060";
$fa-arrow-right: "\f061";
$fa-arrow-up: "\f062";
$fa-arrows: "\f047";
$fa-arrows-alt: "\f0b2";
$fa-arrows-h: "\f07e";
$fa-arrows-v: "\f07d";
$fa-asterisk: "\f069";
$fa-at: "\f1fa";
$fa-automobile: "\f1b9";
$fa-backward: "\f04a";
$fa-ban: "\f05e";
$fa-bank: "\f19c";
$fa-bar-chart: "\f080";
$fa-bar-chart-o: "\f080";
$fa-barcode: "\f02a";
$fa-bars: "\f0c9";
$fa-beer: "\f0fc";
$fa-behance: "\f1b4";
$fa-behance-square: "\f1b5";
$fa-bell: "\f0f3";
$fa-bell-o: "\f0a2";
$fa-bell-slash: "\f1f6";
$fa-bell-slash-o: "\f1f7";
$fa-bicycle: "\f206";
$fa-binoculars: "\f1e5";
$fa-birthday-cake: "\f1fd";
$fa-bitbucket: "\f171";
$fa-bitbucket-square: "\f172";
$fa-bitcoin: "\f15a";
$fa-bold: "\f032";
$fa-bolt: "\f0e7";
$fa-bomb: "\f1e2";
$fa-book: "\f02d";
$fa-bookmark: "\f02e";
$fa-bookmark-o: "\f097";
$fa-briefcase: "\f0b1";
$fa-btc: "\f15a";
$fa-bug: "\f188";
$fa-building: "\f1ad";
$fa-building-o: "\f0f7";
$fa-bullhorn: "\f0a1";
$fa-bullseye: "\f140";
$fa-bus: "\f207";
$fa-cab: "\f1ba";
$fa-calculator: "\f1ec";
$fa-calendar: "\f073";
$fa-calendar-o: "\f133";
$fa-camera: "\f030";
$fa-camera-retro: "\f083";
$fa-car: "\f1b9";
$fa-caret-down: "\f0d7";
$fa-caret-left: "\f0d9";
$fa-caret-right: "\f0da";
$fa-caret-square-o-down: "\f150";
$fa-caret-square-o-left: "\f191";
$fa-caret-square-o-right: "\f152";
$fa-caret-square-o-up: "\f151";
$fa-caret-up: "\f0d8";
$fa-cc: "\f20a";
$fa-cc-amex: "\f1f3";
$fa-cc-discover: "\f1f2";
$fa-cc-mastercard: "\f1f1";
$fa-cc-paypal: "\f1f4";
$fa-cc-stripe: "\f1f5";
$fa-cc-visa: "\f1f0";
$fa-certificate: "\f0a3";
$fa-chain: "\f0c1";
$fa-chain-broken: "\f127";
$fa-check: "\f00c";
$fa-check-circle: "\f058";
$fa-check-circle-o: "\f05d";
$fa-check-square: "\f14a";
$fa-check-square-o: "\f046";
$fa-chevron-circle-down: "\f13a";
$fa-chevron-circle-left: "\f137";
$fa-chevron-circle-right: "\f138";
$fa-chevron-circle-up: "\f139";
$fa-chevron-down: "\f078";
$fa-chevron-left: "\f053";
$fa-chevron-right: "\f054";
$fa-chevron-up: "\f077";
$fa-child: "\f1ae";
$fa-circle: "\f111";
$fa-circle-o: "\f10c";
$fa-circle-o-notch: "\f1ce";
$fa-circle-thin: "\f1db";
$fa-clipboard: "\f0ea";
$fa-clock-o: "\f017";
$fa-close: "\f00d";
$fa-cloud: "\f0c2";
$fa-cloud-download: "\f0ed";
$fa-cloud-upload: "\f0ee";
$fa-cny: "\f157";
$fa-code: "\f121";
$fa-code-fork: "\f126";
$fa-codepen: "\f1cb";
$fa-coffee: "\f0f4";
$fa-cog: "\f013";
$fa-cogs: "\f085";
$fa-columns: "\f0db";
$fa-comment: "\f075";
$fa-comment-o: "\f0e5";
$fa-comments: "\f086";
$fa-comments-o: "\f0e6";
$fa-compass: "\f14e";
$fa-compress: "\f066";
$fa-copy: "\f0c5";
$fa-copyright: "\f1f9";
$fa-credit-card: "\f09d";
$fa-crop: "\f125";
$fa-crosshairs: "\f05b";
$fa-css3: "\f13c";
$fa-cube: "\f1b2";
$fa-cubes: "\f1b3";
$fa-cut: "\f0c4";
$fa-cutlery: "\f0f5";
$fa-dashboard: "\f0e4";
$fa-database: "\f1c0";
$fa-dedent: "\f03b";
$fa-delicious: "\f1a5";
$fa-desktop: "\f108";
$fa-deviantart: "\f1bd";
$fa-digg: "\f1a6";
$fa-dollar: "\f155";
$fa-dot-circle-o: "\f192";
$fa-download: "\f019";
$fa-dribbble: "\f17d";
$fa-dropbox: "\f16b";
$fa-drupal: "\f1a9";
$fa-edit: "\f044";
$fa-eject: "\f052";
$fa-ellipsis-h: "\f141";
$fa-ellipsis-v: "\f142";
$fa-empire: "\f1d1";
$fa-envelope: "\f0e0";
$fa-envelope-o: "\f003";
$fa-envelope-square: "\f199";
$fa-eraser: "\f12d";
$fa-eur: "\f153";
$fa-euro: "\f153";
$fa-exchange: "\f0ec";
$fa-exclamation: "\f12a";
$fa-exclamation-circle: "\f06a";
$fa-exclamation-triangle: "\f071";
$fa-expand: "\f065";
$fa-external-link: "\f08e";
$fa-external-link-square: "\f14c";
$fa-eye: "\f06e";
$fa-eye-slash: "\f070";
$fa-eyedropper: "\f1fb";
$fa-facebook: "\f09a";
$fa-facebook-square: "\f082";
$fa-fast-backward: "\f049";
$fa-fast-forward: "\f050";
$fa-fax: "\f1ac";
$fa-female: "\f182";
$fa-fighter-jet: "\f0fb";
$fa-file: "\f15b";
$fa-file-archive-o: "\f1c6";
$fa-file-audio-o: "\f1c7";
$fa-file-code-o: "\f1c9";
$fa-file-excel-o: "\f1c3";
$fa-file-image-o: "\f1c5";
$fa-file-movie-o: "\f1c8";
$fa-file-o: "\f016";
$fa-file-pdf-o: "\f1c1";
$fa-file-photo-o: "\f1c5";
$fa-file-picture-o: "\f1c5";
$fa-file-powerpoint-o: "\f1c4";
$fa-file-sound-o: "\f1c7";
$fa-file-text: "\f15c";
$fa-file-text-o: "\f0f6";
$fa-file-video-o: "\f1c8";
$fa-file-word-o: "\f1c2";
$fa-file-zip-o: "\f1c6";
$fa-files-o: "\f0c5";
$fa-film: "\f008";
$fa-filter: "\f0b0";
$fa-fire: "\f06d";
$fa-fire-extinguisher: "\f134";
$fa-flag: "\f024";
$fa-flag-checkered: "\f11e";
$fa-flag-o: "\f11d";
$fa-flash: "\f0e7";
$fa-flask: "\f0c3";
$fa-flickr: "\f16e";
$fa-floppy-o: "\f0c7";
$fa-folder: "\f07b";
$fa-folder-o: "\f114";
$fa-folder-open: "\f07c";
$fa-folder-open-o: "\f115";
$fa-font: "\f031";
$fa-forward: "\f04e";
$fa-foursquare: "\f180";
$fa-frown-o: "\f119";
$fa-futbol-o: "\f1e3";
$fa-gamepad: "\f11b";
$fa-gavel: "\f0e3";
$fa-gbp: "\f154";
$fa-ge: "\f1d1";
$fa-gear: "\f013";
$fa-gears: "\f085";
$fa-gift: "\f06b";
$fa-git: "\f1d3";
$fa-git-square: "\f1d2";
$fa-github: "\f09b";
$fa-github-alt: "\f113";
$fa-github-square: "\f092";
$fa-gittip: "\f184";
$fa-glass: "\f000";
$fa-globe: "\f0ac";
$fa-google: "\f1a0";
$fa-google-plus: "\f0d5";
$fa-google-plus-square: "\f0d4";
$fa-google-wallet: "\f1ee";
$fa-graduation-cap: "\f19d";
$fa-group: "\f0c0";
$fa-h-square: "\f0fd";
$fa-hacker-news: "\f1d4";
$fa-hand-o-down: "\f0a7";
$fa-hand-o-left: "\f0a5";
$fa-hand-o-right: "\f0a4";
$fa-hand-o-up: "\f0a6";
$fa-hdd-o: "\f0a0";
$fa-header: "\f1dc";
$fa-headphones: "\f025";
$fa-heart: "\f004";
$fa-heart-o: "\f08a";
$fa-history: "\f1da";
$fa-home: "\f015";
$fa-hospital-o: "\f0f8";
$fa-html5: "\f13b";
$fa-ils: "\f20b";
$fa-image: "\f03e";
$fa-inbox: "\f01c";
$fa-indent: "\f03c";
$fa-info: "\f129";
$fa-info-circle: "\f05a";
$fa-inr: "\f156";
$fa-instagram: "\f16d";
$fa-institution: "\f19c";
$fa-ioxhost: "\f208";
$fa-italic: "\f033";
$fa-joomla: "\f1aa";
$fa-jpy: "\f157";
$fa-jsfiddle: "\f1cc";
$fa-key: "\f084";
$fa-keyboard-o: "\f11c";
$fa-krw: "\f159";
$fa-language: "\f1ab";
$fa-laptop: "\f109";
$fa-lastfm: "\f202";
$fa-lastfm-square: "\f203";
$fa-leaf: "\f06c";
$fa-legal: "\f0e3";
$fa-lemon-o: "\f094";
$fa-level-down: "\f149";
$fa-level-up: "\f148";
$fa-life-bouy: "\f1cd";
$fa-life-buoy: "\f1cd";
$fa-life-ring: "\f1cd";
$fa-life-saver: "\f1cd";
$fa-lightbulb-o: "\f0eb";
$fa-line-chart: "\f201";
$fa-link: "\f0c1";
$fa-linkedin: "\f0e1";
$fa-linkedin-square: "\f08c";
$fa-linux: "\f17c";
$fa-list: "\f03a";
$fa-list-alt: "\f022";
$fa-list-ol: "\f0cb";
$fa-list-ul: "\f0ca";
$fa-location-arrow: "\f124";
$fa-lock: "\f023";
$fa-long-arrow-down: "\f175";
$fa-long-arrow-left: "\f177";
$fa-long-arrow-right: "\f178";
$fa-long-arrow-up: "\f176";
$fa-magic: "\f0d0";
$fa-magnet: "\f076";
$fa-mail-forward: "\f064";
$fa-mail-reply: "\f112";
$fa-mail-reply-all: "\f122";
$fa-male: "\f183";
$fa-map-marker: "\f041";
$fa-maxcdn: "\f136";
$fa-meanpath: "\f20c";
$fa-medkit: "\f0fa";
$fa-meh-o: "\f11a";
$fa-microphone: "\f130";
$fa-microphone-slash: "\f131";
$fa-minus: "\f068";
$fa-minus-circle: "\f056";
$fa-minus-square: "\f146";
$fa-minus-square-o: "\f147";
$fa-mobile: "\f10b";
$fa-mobile-phone: "\f10b";
$fa-money: "\f0d6";
$fa-moon-o: "\f186";
$fa-mortar-board: "\f19d";
$fa-music: "\f001";
$fa-navicon: "\f0c9";
$fa-newspaper-o: "\f1ea";
$fa-openid: "\f19b";
$fa-outdent: "\f03b";
$fa-pagelines: "\f18c";
$fa-paint-brush: "\f1fc";
$fa-paper-plane: "\f1d8";
$fa-paper-plane-o: "\f1d9";
$fa-paperclip: "\f0c6";
$fa-paragraph: "\f1dd";
$fa-paste: "\f0ea";
$fa-pause: "\f04c";
$fa-paw: "\f1b0";
$fa-paypal: "\f1ed";
$fa-pencil: "\f040";
$fa-pencil-square: "\f14b";
$fa-pencil-square-o: "\f044";
$fa-phone: "\f095";
$fa-phone-square: "\f098";
$fa-photo: "\f03e";
$fa-picture-o: "\f03e";
$fa-pie-chart: "\f200";
$fa-pied-piper: "\f1a7";
$fa-pied-piper-alt: "\f1a8";
$fa-pinterest: "\f0d2";
$fa-pinterest-square: "\f0d3";
$fa-plane: "\f072";
$fa-play: "\f04b";
$fa-play-circle: "\f144";
$fa-play-circle-o: "\f01d";
$fa-plug: "\f1e6";
$fa-plus: "\f067";
$fa-plus-circle: "\f055";
$fa-plus-square: "\f0fe";
$fa-plus-square-o: "\f196";
$fa-power-off: "\f011";
$fa-print: "\f02f";
$fa-puzzle-piece: "\f12e";
$fa-qq: "\f1d6";
$fa-qrcode: "\f029";
$fa-question: "\f128";
$fa-question-circle: "\f059";
$fa-quote-left: "\f10d";
$fa-quote-right: "\f10e";
$fa-ra: "\f1d0";
$fa-random: "\f074";
$fa-rebel: "\f1d0";
$fa-recycle: "\f1b8";
$fa-reddit: "\f1a1";
$fa-reddit-square: "\f1a2";
$fa-refresh: "\f021";
$fa-remove: "\f00d";
$fa-renren: "\f18b";
$fa-reorder: "\f0c9";
$fa-repeat: "\f01e";
$fa-reply: "\f112";
$fa-reply-all: "\f122";
$fa-retweet: "\f079";
$fa-rmb: "\f157";
$fa-road: "\f018";
$fa-rocket: "\f135";
$fa-rotate-left: "\f0e2";
$fa-rotate-right: "\f01e";
$fa-rouble: "\f158";
$fa-rss: "\f09e";
$fa-rss-square: "\f143";
$fa-rub: "\f158";
$fa-ruble: "\f158";
$fa-rupee: "\f156";
$fa-save: "\f0c7";
$fa-scissors: "\f0c4";
$fa-search: "\f002";
$fa-search-minus: "\f010";
$fa-search-plus: "\f00e";
$fa-send: "\f1d8";
$fa-send-o: "\f1d9";
$fa-share: "\f064";
$fa-share-alt: "\f1e0";
$fa-share-alt-square: "\f1e1";
$fa-share-square: "\f14d";
$fa-share-square-o: "\f045";
$fa-shekel: "\f20b";
$fa-sheqel: "\f20b";
$fa-shield: "\f132";
$fa-shopping-cart: "\f07a";
$fa-sign-in: "\f090";
$fa-sign-out: "\f08b";
$fa-signal: "\f012";
$fa-sitemap: "\f0e8";
$fa-skype: "\f17e";
$fa-slack: "\f198";
$fa-sliders: "\f1de";
$fa-slideshare: "\f1e7";
$fa-smile-o: "\f118";
$fa-soccer-ball-o: "\f1e3";
$fa-sort: "\f0dc";
$fa-sort-alpha-asc: "\f15d";
$fa-sort-alpha-desc: "\f15e";
$fa-sort-amount-asc: "\f160";
$fa-sort-amount-desc: "\f161";
$fa-sort-asc: "\f0de";
$fa-sort-desc: "\f0dd";
$fa-sort-down: "\f0dd";
$fa-sort-numeric-asc: "\f162";
$fa-sort-numeric-desc: "\f163";
$fa-sort-up: "\f0de";
$fa-soundcloud: "\f1be";
$fa-space-shuttle: "\f197";
$fa-spinner: "\f110";
$fa-spoon: "\f1b1";
$fa-spotify: "\f1bc";
$fa-square: "\f0c8";
$fa-square-o: "\f096";
$fa-stack-exchange: "\f18d";
$fa-stack-overflow: "\f16c";
$fa-star: "\f005";
$fa-star-half: "\f089";
$fa-star-half-empty: "\f123";
$fa-star-half-full: "\f123";
$fa-star-half-o: "\f123";
$fa-star-o: "\f006";
$fa-steam: "\f1b6";
$fa-steam-square: "\f1b7";
$fa-step-backward: "\f048";
$fa-step-forward: "\f051";
$fa-stethoscope: "\f0f1";
$fa-stop: "\f04d";
$fa-strikethrough: "\f0cc";
$fa-stumbleupon: "\f1a4";
$fa-stumbleupon-circle: "\f1a3";
$fa-subscript: "\f12c";
$fa-suitcase: "\f0f2";
$fa-sun-o: "\f185";
$fa-superscript: "\f12b";
$fa-support: "\f1cd";
$fa-table: "\f0ce";
$fa-tablet: "\f10a";
$fa-tachometer: "\f0e4";
$fa-tag: "\f02b";
$fa-tags: "\f02c";
$fa-tasks: "\f0ae";
$fa-taxi: "\f1ba";
$fa-tencent-weibo: "\f1d5";
$fa-terminal: "\f120";
$fa-text-height: "\f034";
$fa-text-width: "\f035";
$fa-th: "\f00a";
$fa-th-large: "\f009";
$fa-th-list: "\f00b";
$fa-thumb-tack: "\f08d";
$fa-thumbs-down: "\f165";
$fa-thumbs-o-down: "\f088";
$fa-thumbs-o-up: "\f087";
$fa-thumbs-up: "\f164";
$fa-ticket: "\f145";
$fa-times: "\f00d";
$fa-times-circle: "\f057";
$fa-times-circle-o: "\f05c";
$fa-tint: "\f043";
$fa-toggle-down: "\f150";
$fa-toggle-left: "\f191";
$fa-toggle-off: "\f204";
$fa-toggle-on: "\f205";
$fa-toggle-right: "\f152";
$fa-toggle-up: "\f151";
$fa-trash: "\f1f8";
$fa-trash-o: "\f014";
$fa-tree: "\f1bb";
$fa-trello: "\f181";
$fa-trophy: "\f091";
$fa-truck: "\f0d1";
$fa-try: "\f195";
$fa-tty: "\f1e4";
$fa-tumblr: "\f173";
$fa-tumblr-square: "\f174";
$fa-turkish-lira: "\f195";
$fa-twitch: "\f1e8";
$fa-twitter: "\f099";
$fa-twitter-square: "\f081";
$fa-umbrella: "\f0e9";
$fa-underline: "\f0cd";
$fa-undo: "\f0e2";
$fa-university: "\f19c";
$fa-unlink: "\f127";
$fa-unlock: "\f09c";
$fa-unlock-alt: "\f13e";
$fa-unsorted: "\f0dc";
$fa-upload: "\f093";
$fa-usd: "\f155";
$fa-user: "\f007";
$fa-user-md: "\f0f0";
$fa-users: "\f0c0";
$fa-video-camera: "\f03d";
$fa-vimeo-square: "\f194";
$fa-vine: "\f1ca";
$fa-vk: "\f189";
$fa-volume-down: "\f027";
$fa-volume-off: "\f026";
$fa-volume-up: "\f028";
$fa-warning: "\f071";
$fa-wechat: "\f1d7";
$fa-weibo: "\f18a";
$fa-weixin: "\f1d7";
$fa-wheelchair: "\f193";
$fa-wifi: "\f1eb";
$fa-windows: "\f17a";
$fa-won: "\f159";
$fa-wordpress: "\f19a";
$fa-wrench: "\f0ad";
$fa-xing: "\f168";
$fa-xing-square: "\f169";
$fa-yahoo: "\f19e";
$fa-yelp: "\f1e9";
$fa-yen: "\f157";
$fa-youtube: "\f167";
$fa-youtube-play: "\f16a";
$fa-youtube-square: "\f166";


$padding-base-horizontal:        12px;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-yellow:          #f0b155;
$brand-purple:          #3f1a4d;
$brand-light-grey:      #F4F4F4;
$brand-red:             #971111;

$brand-primary:         #424242;
$brand-secondary:         $brand-yellow;

// Fonts
$font-family-sans-serif: "proxima-nova", helvetica, arial, sans-serif;
$font-family-serif:		 "kepler-std", $font-family-sans-serif;
$font-family-base:       $font-family-sans-serif;
$font-weight-base:		 400;
$font-size-base:         20px;

$headings-font-family:   $font-family-serif;
$headings-font-weight:    900;

$link-transition: 0.3s ease-in-out all;

$font-size-h1: 1.6695em; // 33.39 px
$font-size-h2: 1.5em; // 30 px
$font-size-h3: 1.4em; // 28 px
$font-size-h4: 1.3em; // 26 px
$font-size-h5: 1.25em; // 25 px
$font-size-h6: 1.125em; // 22.5 px

$fa-caret-right: "\f0da";

// Grays
// -------------------------

$gray-darker:            lighten(#000, 13.6%); // #222
$gray-dark:              lighten(#000, 20%);   // #333
$gray:                   lighten(#000, 33.5%); // #555
$gray-light:             lighten(#000, 60%);   // #999
$gray-lighter:           lighten(#000, 93.5%); // #eee

$text-color: #000;

$border-radius-small:            3px;

$line-height-base:        1.55; // 20/14
$line-height-computed:    floor($font-size-base * $line-height-base); // ~20px

// Navbar
// -------------------------

$header-height: 60px;
$header-logo:   url('../images/logo.png');

// !! added
$header-desktop-height: 152px;
$header-logo-height: $header-desktop-height - 30;
$header-logo-width: 170px;

$header-small-height: 100px;

$highlight-background: $brand-secondary;
$highlight-color: #fff;


// Basics of a navbar
$navbar-height:                    50px;
$navbar-margin-bottom:             0;
$navbar-border-radius:             0;
$navbar-padding-horizontal:        0;
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2);

$navbar-default-color:             $text-color;
$navbar-default-bg:                #fff;
$navbar-default-border:            lighten($brand-secondary, 50%);

// Navbar links
$navbar-default-link-color:                $text-color;
$navbar-default-link-hover-color:          $brand-secondary;
$navbar-default-link-hover-bg:             rgba(255,255,255,0.4);
$navbar-default-link-active-color:         $brand-secondary;
$navbar-default-link-active-bg:            transparent;
$navbar-default-link-disabled-color:       #ccc;
$navbar-default-link-disabled-bg:          transparent;

// !! GH added
$navbar-font: 							   $headings-font-family;
$navbar-font-weight: 					   700;
$navbar-text-transform:                    uppercase;


// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #fff;
$navbar-default-toggle-icon-bar-bg:        $brand-primary;
$navbar-default-toggle-border-color:       transparent;
// !! added
$navbar-default-toggle-bg:					transparent;
$navbar-default-toggle-radius:				$border-radius-small;
$navbar-default-toggle-icon-bar-radius:		$border-radius-small;

// Inverted navbar
//
// Reset inverted navbar basics
$navbar-inverse-color:                      $gray-light;
$navbar-inverse-bg:                         #222;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color:                 $gray-light;
$navbar-inverse-link-hover-color:           #fff;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color:        #444;
$navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color:          #fff;
$navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333;
$navbar-inverse-toggle-icon-bar-bg:         #fff;
$navbar-inverse-toggle-border-color:        #333;


// Navs
// -------------------------

$nav-link-padding:                          10px 15px;
$nav-link-hover-bg:                         $gray-lighter;

$nav-disabled-link-color:                   $gray-light;
$nav-disabled-link-hover-color:             $gray-light;

$nav-open-link-hover-color:                 #fff;
$nav-open-caret-border-color:               #fff;



// Flex Nav Depths Background and Link Colour
// -------------------------

// !! added
$flex-color: 				$brand-primary;
$flex-icon-closed:			$fa-angle-right;
$flex-icon-open:			$fa-angle-down;

$top-level:                 #eee;
$top-level-hover:           lighten($top-level, 3%);
$second-level:              lighten($top-level, 3%);
$third-level:               lighten($top-level, 5%);
$third-level-text-color:    $flex-color;
$fourth-level:              lighten($top-level, 7%);
$fourth-level-text-color:   $flex-color;
$fifth-level:               lighten($top-level, 10%);
$border-top-level:          rgba(0,0,0, 0.1);
$border-second-level:       $border-top-level;
$flex-align:                left;


// Subnavs
// -------------------------
// !! added

// .box-subnav (subnav in a coloured box with padding)
$subnav-border-radius:		5px;
$subnav-bg:					lighten($brand-primary, 45%);
$subnav-padding:			15px;
//

$subnav-border:				1px solid darken($subnav-bg, 15%);
$subnav-indent:             $padding-base-horizontal;

$subnav-font-family:		$font-family-base;
$subnav-font-weight:		300;
$subnav-font-size:			16px;

$subnav-link-color:			$brand-primary;
$subnav-link-bg:			transparent;
$subnav-link-border:		$subnav-border;

$subnav-link-color-hover:	$brand-secondary;
$subnav-link-bg-hover:		lighten($brand-primary, 50%);

$subnav-link-color-active:	white;
$subnav-link-bg-active:		$brand-primary;

$subnav-icon:               $fa-caret-right; // eg. $fa-caret-right
$subnav-icon-color:         $subnav-link-color;


// !! added
$subnav-lead-font-size: 	$font-size-h3;
$subnav-lead-font-family:	$headings-font-family;
$subnav-lead-font-weight:	300;
$subnav-lead-color:			$brand-secondary;

// !! added
$subnav-second-level-link-color:       	$subnav-link-color;
$subnav-second-level-bg:               	$subnav-link-bg;
$subnav-second-level-font-size: 		0.9*$subnav-font-size;

// !! added
$subnav-third-level-link-color:       	$subnav-link-color;
$subnav-third-level-bg:               	$subnav-link-bg;
$subnav-third-level-font-size: 			0.85*$subnav-font-size;
