	@mixin transform($transform) {
	-webkit-transform: $transform;
	-moz-transform: $transform;
	-ms-transform: $transform;
	transform: $transform;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

blockquote {
	background: #efefef;
	border:0;

	p {
		font-family: $font-family-serif;
		font-weight:bold;
		color: $brand-purple;
	}
}

.page-content a {
	font-weight: bold;
	color: #971111;

	&:hover, &:focus {
		border-color:$brand-purple;
		text-decoration:none;
		color: $brand-yellow;
		border-bottom: 2px solid #971111;
	}
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media print {
	header .login-btn,
	.banner-container,
	.call-to-actions,
	.pre-footer,
	.banner-image,
	.banner-image .caption,
	footer,
	#breadcrumbs,
	.navbar-toggle	{
		display: none;
	}
	.brand-logo {
		display: block;
	}
}

.banner, .logo {
    clear: both;
}
