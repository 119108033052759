header {
   padding-top: 13px;

	 @media (min-width: $screen-sm-min) {
	   padding-top: 22px;
	 }
}


.banner {
  max-width: 1430px;
  margin: 0 auto;
  position: relative;
  padding-top: 60px;

  @media (min-width: $screen-sm-min) {
	  padding-top:0;
  }
}

.logo {
	background: url(../images/logo.png);

	background-position: left top;
	background-repeat: no-repeat;
	text-indent: -999999px;
	-webkit-background-size: contain;
	background-size: contain;
	padding: 0;
	margin: 0 auto;
	display: block;
	float:left;
	width: 70%;
	position: relative;
	height: 99px;

	@media (min-width: $screen-md-min) {
		background-position: center top;
		height: 103px;
		float: none;
		width: 100%;
	}
}

.banner-image {
	position: relative;
	//height: 603px;
	width:100%;
	background-size: cover;
	background-image:inherit;

  @media (min-width: $screen-md-min) {
		margin-bottom: 122px;
		padding-bottom:43%;
  }

  img {
	  max-width: 100%;
	  height:auto;
  }

  .home & {
	width:100%;
 	margin-bottom: 31px;

	 @media (min-width: $screen-md-min) {
		padding-bottom:50.785714285714%;
		margin-bottom: 139px;

		 .caption {
			   bottom: -49px;
				padding: 26px 50px 16px 50px;

				p.wp-caption-text {
					line-height: 57.85px;
				}
		 }
	 }
  }

  .caption {
	 color: #fff;
	padding: 26px 50px 7px 50px;
	 text-align: center;
	 display: block;
	 width:100%;
	 background: rgba($brand-purple, 1);

	 @media (min-width: $screen-md-min) {
  		padding: 20px 50px;
		bottom:  -103px;
		padding: 38px 30px 18px 30px;
		background: rgba($brand-purple, 0.85);
		max-width: 757px;
		position: absolute;
		bottom: -70px;
		left: 50%;
		@include transform(translateX(-50%));
	 }

	 p.wp-caption-text {
		font-family: $headings-font-family;
		font-weight: $headings-font-weight;
		line-height:1.07221006564551em; //49px;
		font-size: 1.25em; // 45.7 px

		@media (min-width: $screen-sm-min) {
			font-size: 2em;
		}

		@media (min-width: $screen-md-min) {
			font-size: 2.6425em; // 52.85px
		}
	 }
  }
}

.btn-search {
	max-height: 43px;
}
