@import "common/variables";
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower
@import "common/global";
@import "common/type";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/call-to-actions";
@import "components/grid";
@import "components/wp-classes";
@import "components/navs";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

@mixin vertical-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin circle($radius) {
	width:$radius;
	height:$radius;
	border-radius:($radius/2);
}

@mixin transform($transform) {
	-moz-transform: $transform;
	-o-transform: $transform;
	-ms-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

// Documentation: http://bamcms2.6.dev1.bam.com.au/new-to-bamcms/scss-mixins/rowgutter/
// Usage
// @include row-gutter(rowValue, gutterValue);
// Example
// @include row-gutter(15px, 20px);

@mixin row-gutter($gutter:($grid-gutter-width / 2), $container-gutter:($grid-gutter-width / 2)) {
	@if $container-gutter < $gutter {
		padding-right: $gutter;
		padding-left: $gutter;
	} @else {
		padding-right: $container-gutter;
		padding-left: $container-gutter;
	}

	.row {
		margin-right: -($gutter);
		margin-left: -($gutter);

		> [class*=" col-"], > [class^="col-"] {
			padding-right: $gutter;
			padding-left: $gutter;
		}
	}
}

#skip-to-content, #skip-to-menu {
	border: 2px solid $brand-primary;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	opacity:0;
	position: fixed;
	outline: none;
	top: 10px;
	left: 10px;
	padding: 10px;
	white-space: nowrap;
	width: 1px;
	background: $brand-primary;
	color: #fff;
	text-decoration: none;
	@include transition($link-transition);
	z-index: 1040;
	font-weight:bold;

	&:focus {
		clip: auto;
		height: auto;
		opacity:1;
		// margin:0;
		overflow: visible;
		white-space: normal;
		width: auto;
		outline: none;
	}

	&:hover {
		background: #fff;
		color: $brand-primary;
	}
}


html {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

.container-fluid {
	max-width: 1430px;
	padding-left: 25px;
	padding-right: 25px;
	margin-right: auto;
	margin-left: auto;

	@media (max-width: $screen-sm-min) {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.page-header {
	margin: 35px 0 19px;
	padding-bottom: 14px;
	padding-right: 40px;
	position: relative;
	border-bottom: 0;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 213px;
		height: 3px;
		background: $brand-purple;
		display: block;
	}

	h1 {
		margin-bottom: 0;
	}
}

.page-content {
	max-width: 1160px;
	margin: 0 auto;

	p {
		line-height: 1.5em; // 30px
		margin-bottom: 1.5em; // 30px
	}
}

body.page {
	border-top: 2px solid $brand-yellow;
}

.about-video {
	@include clearfix();
	margin-bottom: 76px;
	position: relative;

	@media (min-width: $screen-md-min) {
		margin-bottom: 37px;
	}

	.hero-text {
		position: relative;

		@media (min-width: $screen-sm-min) {
			padding: 0 30px;
		}

		@media (min-width: $screen-md-min) {
			padding: 0;
			margin-top: 0;
		}

		.banner-description {
			font-size: 0.8em;

			@media (min-width: $screen-sm-min) {
				font-size: 1em;
			}
		}

		h2.banner-title {
			font-size: 33px;

			@media (min-width: $screen-sm-min) {
				font-size: 1.5em;
			}
		}
	}

	.home & {
		.hero-text h2.banner-title {
			@media (min-width: $screen-sm-min) {
				font-size: 33.39px;
				line-height: 1.2em;
				margin-top: 26px;
				padding-bottom: 22px;
				margin-bottom: 28px;
			}
		}
		.hero-image {
			position:relative;
			background:#000;

			@media (min-width: $screen-sm-min) {
				max-height: 478px;
			}

			img {
				opacity:0.55;
			}

			.hero-image-overlay {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 9;
				pointer-events:none;

				&:after {
					content: "\f144";
					color: #eeeeef;
					opacity: 0.27;
					font-size: 112.92px;
					font-family: "FontAwesome";
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					left: 0;
					right: 0;
					text-align: center;
					@include transition($link-transition);
				}
			}

			&:hover {
				.hero-image-overlay:after {
					opacity:0.55;
				}
			}
		}

		.inner {
			padding: 3px 58px 71px 28px;

			.banner-description {
				@media (min-width: $screen-sm-min) {
					font-size: 0.8em;
					line-height: 1.5em;
				}
			}
		}
	}

	@media(min-width:$screen-md) {
		&.image-left .hero-text,
		&.image-right .hero-text {
			float: left;
			width: 47.2%;
		}
		.hero-image {
			float: left;
			width: 50%;
		}
		&.image-right .hero-image {
			float: right;
		}
	}

	.hero-image {
		overflow: hidden;
		height: 160px;
		cursor: pointer;

		@media(min-width:$screen-sm) {
			height: 200px;
		}

		@media(min-width:$screen-md) {
			height: 525px;
		}

		@media(min-width:$screen-lg) {

		}

		img {
			position: relative;
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
	}
	.video-btn {
		clear: both;
		text-align: center;
		bottom: -25px;
		position: absolute;
		right: 36px;

		@media (min-width: $screen-sm-min) {
			width:100%;
			left:0;
			bottom: -37px;
		}

		.btn-purple {
			font-size: 16.67px;
			padding: 13px 15px;
			font-weight: bold;

			@media (min-width: $screen-sm-min) {
				font-size:1.2em;
				padding: 19px 23px;
			}

			i {
				padding-left: 14px;
			}
		}
	}
	.inner {
		position: relative;
		width: 100%;
		padding: 4px 28px 14px;
		max-width: 960px;
		float: none;
		margin: 0 auto;
		background: $brand-light-grey;

		@media (min-width: $screen-sm) {
			padding: 30px;
		}

		@media (min-width: $screen-md) {
			width: 111%;
			top: 50px;
		}

		h2 {
			margin-bottom: 25px;
			padding-bottom: 20px;
			line-height: 1.2em;
			padding-bottom: 14px;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				max-width: 336px;
				height: 3px;
				background: $brand-purple;
				display: block;
			}
			@media (min-width: $screen-md-min) {
				line-height: 1.1em;
			}
		}
		h3 {
			font-size: 1.2em; // 24px
			margin-top: 1.33333333333333em;
			margin-bottom: 0.41666666666667em;
		}
		.our-services {
			ul {
				font-size: 0.9em;
				padding-left: 1em;

				li {
					padding-bottom: 9px;
					padding-left: 1.25em;
					list-style: none;
					position: relative;

					&:before {
						font-family: "FontAwesome";
						content: "\f111";
						position: absolute;
						top: 0.4em;
						left: 0;
						font-size: 10px;
					}
				}
			}
		}
		.banner-description {
			font-size: 0.9em;
			line-height: 1.33333333333333em;
			p {
				margin: 0 0 10px;
			}
			@media (min-width: $screen-md-min) {
				font-size: 1em;
			}
		}
	}
}

.services-list {
	margin-top: 60px;
	max-width: 1430px;
	padding-left: 25px;
	padding-right: 25px;
	margin-right: auto;
	margin-left: auto;

	@media (min-width: $screen-sm-min) {
		margin-top: 66px;
		margin-bottom: calc(28px + 41px);
		display: flex;
		flex-wrap: wrap;
	}

	.service {
		border-bottom: 3px solid #ebebeb;
		padding-top: 21px;
		padding-bottom: 28px;

		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			border-top: 3px solid #ebebeb;
		}

		@media (min-width: $screen-sm-min) {
			width: 33.33%;
			padding-left: 15px;
			padding-right: 15px;
		}

		h4 {
			font-size: 1.2em;
			margin-top: 18px;
			margin-bottom: 3px;
		}

		p {
			clear: both;
			font-size: .8em;
			line-height: 1.5em;
		}
	}

	.row {
		min-height: 179px;
	}

	hr {
		border-top: 3px solid #ebebeb;
		margin-top: 21px;
		margin-bottom: 28px;
	}

	img {
		width: 45px;
		float: left;
		margin: 0;
		margin-top: 6px;
		margin-right: 14px;
		border: 3px solid $brand-yellow;
		@include border-radius(50%);
		@include transition($link-transition);

		&:hover {
			background: $brand-yellow;
		}
	}

	.service-description {
		clear: both;
		padding-top: 10px;
		font-size: 0.8em;
		line-height: 1.5em;
		padding-top: 5px;
	}
}
.news-stories {
	background: $brand-light-grey;
	padding: 63px 0 89px 0;
	margin-top: 30px;
	margin-top: 41px;

   .image-container {
   	text-align: center;
		position: absolute;
		width: 100%;
		top: -20px;
      margin: 0 auto 6px;
      padding: 0;
      border-radius: 100%;
      border: 7px solid $brand-yellow;
      background-image: inherit;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      max-width: 122px;
		z-index: 2;

		@media (min-width: $screen-md-min) {
			top: 28px;
         max-width: 396px;
			position: relative;
		}

      img {
   		border-radius: 50%;
   		border: 7px solid $brand-yellow;
   		width: 100%;
   		max-width: 391px;
   		margin-bottom: 0;
   		position: relative;
   	}

      &:before {
         content: "";
         padding-bottom: 100%;
			display: block;
      }
   }

	.image-container {
		top: 25px;
		margin: 0 25px 3px;

		img {
			max-width: 396px;
		}
	}

	h2 {
		text-align: center;
		margin: 0 auto;
		//border-bottom: 3px solid #3f1a4d;
		margin-bottom: 40px;
		padding-bottom: 20px;
		font-size: 33.39px;
		padding-bottom: 25px;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			max-width: 196px;
			height: 3px;
			background: $brand-purple;
			display: block;
			margin: 0 auto;
			right:0;
		}
	}

	.news-description {
		background: #fff;
		//padding: 25px 20px;
		margin-bottom: 50px;
		padding: 25px 43px 25px 30px;
		min-height: 312px;

		h4 {
			font-size: 1.2em;
			line-height: 33.8px;
		}

		p {
			font-size: 0.8em;
			line-height:1.5em;

		}
	}

	hr {
		border-top: 3px solid #3f1a4d;
		//width: 60%;
		//margin: 20px 0;
		width: 58%;
		margin: 19px 0 28px 0;
	}
	.btn {
		position: absolute;
		bottom: 11px;
		left: 43px;
		font-weight: bold;
		font-size: 23px;
		padding: 21px 24px 19px 27px;

		i {
			padding-left: 17px;
		}
	}
}
#breadcrumbs {
	font-size: 0.6em; // 12px
	text-transform: uppercase;
	margin-left: 2px;
	margin-top: 31px;

	a {
		padding: 0 8px;

		&:first-child {
			padding-left: 0;
		}
	}

	span a:first-child {
		padding-left: 8px;
	}

	.breadcrumb_last {
		padding: 0 8px;
	}
}
.sidebar-contact-form {

	@media (min-width: $screen-sm-min) {
		margin-top: 60px;
		margin-bottom: 30px;
	}
}
.modal .entry-content-asset {
	position: relative;
	padding-bottom: 56.25%;
	/* 16:9 */
	padding-top: 25px;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
#videoModal {
	.modal-header {
		background:transparent;
		border:0;
		text-align:right;

		.close {
			font-size: 0.8em;
			color: #fff;
			width:40px;
			height:40px;
			line-height:37px;
			text-align:center;
			text-shadow:none;
			opacity: 1;
			border-radius:50%;
			border: 3px solid $brand-yellow;

			&:hover, &:focus {
				background:$brand-yellow;
				color: #fff;
				outline:none;
			}
		}
	}

	.modal-content {
		background: transparent;
		border:0;
		box-shadow:none;
	}

	.modal-body {
		padding: 0;
		border-radius: 0;
	}
}

.modal-backdrop.in {
	opacity: 0.8;
}

#wppb-login-wrap {
	max-width: 400px;
	margin: 0 auto;
}

#loginform {
	label {
		font-weight: normal;
	}

	.login-remember {
		font-size: 0.8em;

		label {
			float: right;
		}
	}

	.input {
		border-radius: 0;
		border: 2px solid #ccc;
		background: #fff;
		@include transition($link-transition);

		&:focus {
			border-color: $brand-yellow;
			outline: none;
		}
	}

	#wppb-login-wrap & {

		.login-username, .login-password {
			input, label {
				width: 100%;
				float: none;
				display: block;
			}

			input {
				padding: 6px 12px;
			}
		}
	}

	.button-primary {
		background: $brand-yellow;
		color: #000;
		@include transition($link-transition);
		font-weight: bold;
		padding: 14px 20px;
		width: 100%;
		text-transform: uppercase;

		&:hover, &:focus {
			background: darken($brand-yellow, 5%);
			outline:none;
		}
	}
}

#loginModal {
	.modal-content {
		border-radius:0;
		border: 0;
		background:transparent;
		box-shadow: none;

		.wppb-error {
			background:darkred;
			border-color:darkred;
			font-size:0.75em;

			a {
				color: #fff;
				text-decoration:underline;

				&:hover, &:focus {
					color: $brand-yellow;
				}
			}
		}

		.modal-header {
			border:0;
			text-align:right;

			.close {
				font-size: 0.8em;
				color: #fff;
				width:40px;
				height:40px;
				line-height:37px;
				text-align: center;
				text-shadow: none;
				opacity: 1;
				border-radius: 50%;
				border: 3px solid $brand-yellow;

				&:hover, &:focus {
					background:$brand-yellow;
					color: #fff;
					outline:none;
				}
			}
		}

		.modal-body {
			background: rgba($brand-purple, 0.85);

			.widget_text {
				h3 {
					text-align: center;
					color: #fff;
				}

				.textwidget {
					color: #fff;
					padding: 30px 20px;

					label {
						font-weight: normal;
					}

					.login-remember {
						font-size: 0.8em;

						label {
							float: right;
						}
					}

					.input {
						border-radius: 0;
						border: 2px solid #fff;
						background: #fff;
						@include transition($link-transition);

						&:focus {
							border-color: $brand-yellow;
							outline: none;
						}
					}

					.button-primary {
						background: $brand-yellow;
						color: #000;
						@include transition($link-transition);
						font-weight: bold;
						padding: 14px 20px;
						width: 100%;
						text-transform: uppercase;

						&:hover, &:focus {
							background: darken($brand-yellow, 5%);
						}
					}
				}
			}
		}
	}
}

#subForm {
	margin: 0 auto 30px;

	width: auto;
	max-width: 400px;

	input, button {
		width:100%;
	}
}

.noscript-message {
	z-index: 2000;
	background: #b94a48;
	color: #fff;
	padding: 10px;
	width: 100%;
	text-align: center;
}
