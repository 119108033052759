// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

label {
	font-weight:normal;
}

input#wppb-submit {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 0;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.428571429;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form-control {
	@include border-radius(0);
	box-shadow:none;

	&:focus {
		box-shadow:none;
		border-color: $brand-secondary;
	}
}

textarea {
	max-width: 100%;
}

form {
.wppb-form-field input, .wppb-form-field input[type="text"], .wppb-form-field input[type="email"], .wppb-form-field input[type="url"], .wppb-form-field input[type="password"], .wppb-form-field input[type="search"], .wppb-form-field select, .wppb-form-field textarea, .wppb-checkboxes, .wppb-radios, #wppb-login-wrap .login-username input, #wppb-login-wrap .login-password input, select {
		border-radius: 0;
		border: 1px solid #ccc;
		background: #fff;
		padding: 6px 12px;
		@include transition($link-transition);

		&:focus {
			border-color: $brand-yellow;
			outline: none;
		}
	}
}
