.btn {
  border-radius: 0;
  @include transition($link-transition);

  &:focus {
	  outline:none;
  }
}

.btn-lg {
  padding: 20px 30px;
}

.btn-yellow {
  @include button-variant($btn-default-color, $brand-yellow, $brand-yellow);
}

.submit.button {
	@include button-variant($btn-default-color, $brand-yellow, $brand-yellow);
	border:0;
	padding: 7px 14px;
	@include transition($link-transition);

	&:focus {
		outline:none;
	}
}

.btn-purple{
  @include button-variant(#fff, $brand-purple, $brand-purple);
}

.btn-black {
  @include button-variant(#fff, #282828, #282828);
}

.btn-light-grey {
  @include button-variant(#000, $brand-light-grey, $brand-light-grey);
}

.btn-search {
	color: $brand-purple;
	background: #f4f4f4;
	border-bottom: 1px solid #cccccc;
	padding: 7px 14px;

	&:hover, &:focus {
		background: $brand-purple;
		color: #fff;
	}
}

.btn-black i, .btn-purple i, .btn-yellow  i{
  padding-left: 10px;
}

.btn-brand {
	background:#282828;
	border:0;
	color: #fff;

	&:hover, &:focus {
		color: #fff;
		background:#0f0f0f;
	}

	&:after {
		content: $fa-caret-right;
		font-family:"FontAwesome";

	}
}

.navbar-header .login-btn {
	position: absolute;
	right: 14px;
	top: 49px;
      z-index: 999;
}

.login-btn, .wppb-logout-url {
	font-size:0.7em; // 14px;
	text-transform:uppercase;
	background: #f4f4f4;
	border-color: #f4f4f4;
	color: #515151;
   padding: 12px 15px 11px 17px;

	.logged-in & {
		background: #f6d1da;
		border-color: #f6d1da;

		&:hover, &:focus {
			background: darken(#f6d1da, 5%);
			border-color: darken(#f6d1da, 5%);
			color: #515151;
		}
	}

	&:hover, &:focus {
		text-decoration:none;
	}
}
