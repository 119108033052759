a, button, .btn {
	@include transition($link-transition);
}

h1 {
	line-height:1.2em;
}

::selection {
	background: $highlight-background; /* WebKit/Blink Browsers */
	color: $highlight-color;
}

::-moz-selection {
	background: $highlight-background; /* Gecko Browsers */
	color: $highlight-color;
}

.table-primary {
	width: 100%;
	max-width: 1160px;
  margin: 0 auto 30px;
	font-size: 0.9em;

	td {
		padding: 5px 10px;

		a {
			color: $brand-red;
			font-weight: bold;

			&:hover,
			&:focus {
				color: $brand-secondary;
				text-decoration: none;
			}
		}
	}

	thead {
		tr {

			td {
				font-weight: bold;
				background: #e7e4e4;
			}
		}
	}

	tbody tr {

		td {
		}

		&:nth-child(odd) {
			td {
				background: lighten(#e7e4e4, 8%);
			}
		}

		&:nth-child(even) {
			td {
				background: #e7e4e4;
			}
		}

	}
}
