.home .call-to-actions {
   margin-top: 22px;

	.container-fluid {
		padding-left:25px;
		padding-right:25px;
	}

	@media (min-width: $screen-md-min) {
		margin-top: -50px;
	}
}

.call-to-actions {
	margin-bottom: 50px;
	margin-top: 62px;

	.container-fluid {
		padding-left:0;
		padding-right:0;
	}

	@media (min-width: $screen-md-min) {
		margin-bottom: 102px;
		margin-top: 0;
	}

	.cta-container {
		position: relative;
		margin-bottom:30px;
		margin: 0 0 13px;
	}

   .image-container {
   	text-align: center;
		position: absolute;
		width: 100%;
		top: -20px;
      margin: 0 auto 6px;
      padding: 0;
      border-radius: 100%;
      border: 7px solid $brand-yellow;
      background-image: inherit;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      max-width: 122px;
		z-index: 2;

		@media (min-width: $screen-md-min) {
			top: 28px;
         max-width: 396px;
			position: relative;
		}

      img {
   		border-radius: 50%;
   		border: 7px solid $brand-yellow;
   		width: 100%;
   		max-width: 391px;
   		margin-bottom: 0;
   		position: relative;
   	}

      &:before {
         content: "";
         padding-bottom: 100%;
			display: block;
      }
   }

  .banner-description {
		background: $brand-light-grey;
		//padding: 30px;
		font-size: 1em; // 16px
		line-height: 1.5em; // 24px\
		padding: 10px;
		position: relative;
		right: 0;
		width: 100%;
		padding-left: 82px;
		margin-bottom: 60px;
		border-left: 60px solid #fff;

		@media (min-width: $screen-md-min) {
			min-height: 299px;
			position:relative;
			width:100%;
			margin-bottom: 0;
			border-left:0;
			padding: 30px 25px 48px;
			@include transform(translateY(0));
		}

		h2 {
			//word-spacing: 200px;

			line-height:1.41666666666667em; // 34px
			font-size: 1.2em;
			position:relative;
			margin:0;
			padding: 12px 0 16px 0;

			@media (min-width: $screen-md-min) {
				font-size: 33px;
				padding: 7px 0 11px 0;
			}

			@media (min-width: $screen-md-min) {
				margin-bottom: 20px;
				font-size: 1.2em;
				padding-bottom: 0.5em; // 12px
				margin-top:0.5em; // 12px;
				margin-bottom: 1.04166666666667em; // 25px;
			}

			&:after {
				position:absolute;
				bottom:0;
				left:0;
				max-width:253px;
				width:100%;
				height:3px;
				background:$brand-purple;
				display:block;

				@media (min-width: $screen-md-min) {
					content:"";
				}
			}
		}

		.cta-desc {
			display:none;

			@media (min-width: $screen-md-min) {
				display:block;
			}
		}
	}

  .btn {
		position: absolute;
		font-weight: bold;
		font-size: 16.56px;
		padding: 13px 15px;
		right: 21px;
   	bottom: -22px;

		@media (min-width: $screen-md-min) {
			right: auto;
			font-size: 22.97px;
			padding: 19px 19px 18px 24px;
			bottom: -37px;
			right: 40px;
		}

		i {
			padding-left: 14px;
		}
  }

  .home & {
	  @media (min-width: $screen-md-min) {
		   margin-bottom: 93px;
	  }

	  .banner-description {
		  @media (min-width: $screen-md-min) {
				padding: 20px 36px 48px 29px;
				min-height: 314px;
			}
	  }

	  .cta-desc {
		  @media (min-width: $screen-md-min) {
			   font-size: 0.8em;
				line-height: 1.5em;
		  }
	  }

	  .btn-yellow {
		  @media (min-width: $screen-md-min) {
			  right:auto;
			  left: 29px;
			   padding: 21px 26px 19px 24px;
				bottom: -39px;

			  i {
				   padding-left: 18px;
			  }

		  }
	  }

	  h2 {
		  @media (min-width: $screen-md-min) {
			  font-size:33.39px;
			  margin-top: 0;
			  line-height:1.2em;
			   padding-bottom: 22px;
				margin-bottom:28px;
		  }
	  }
  }
}
