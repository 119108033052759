.datebox {
	background: $brand-purple;
	color: #fff;
	padding: 16px 20px 10px;
	text-align:center;
	text-transform: uppercase;
	font-size: 0.8em;
	float:left;
	margin-right:15px;

	span {
		display:block;
		line-height:1em;
		font-weight:bold;

		&.day {
			font-size:2.5em;
		}

		&.month {
			font-size:0.9em;
			letter-spacing: 0.5px
		}

		&.year {
			font-size:1em;
			margin-bottom:5px;
		}
	}
}

h2.entry-title {
	margin-top:0;

	a {
		&:hover, &:focus {
			color: $brand-yellow;
			text-decoration: none;
		}
	}
}

article {
	padding-bottom: 30px;
	max-width: 1160px;
   margin: 0 auto;

	header {
		padding-top: 0;
	}
}
