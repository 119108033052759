.pre-footer {
	background: $brand-light-grey;
   padding: 63px 0 74px;
	position: relative;

	.widget {
		background: #fff;
		padding: 28px 29px 54px;
		position: relative;
		margin-bottom: 30px;
	}

	h4 {
		//width: 400px;
		//border-bottom: 3px solid #3f1a4d;
		margin-bottom: 20px;
		padding-bottom: 11px;
		font-size: 1.2em; // 24px
		position:relative;

		&:after {
			content:"";
			position:absolute;
			bottom:0;
			left:0;
			max-width:418px;
			width:100%;
			height:3px;
			background:$brand-purple;
			display:block;
		}
	}

	p {
		line-height: 1.5em;
	}

	.btn-black {
		position: absolute;
		bottom: -41px;
		right: 30px;
		font-weight: 700;
		font-size: 24px;
	   padding: 20px 21px 18px 27px;
	}
}

footer {
   padding: 33px 0 11px;
	background: $brand-light-grey;

	@media (min-width: $screen-md-min) {
		background: #fff;
		padding: 57px 0 82px;
	}

	.home & {
		padding: 68px 0 11px;
	}

	li.menu-item {
   	margin-bottom: 43px;

		a {
			font-weight: bold;
			font-size: 0.8em;
			border-bottom: 3px solid $brand-yellow;
			padding:0;
		   padding-bottom: 8px;

			&:hover, &:focus {
				background: transparent;
			}
		}

		li {
			margin-bottom:0;
		}
	}

	#menu-footer-menu-3 {
		li.menu-item.menu-item-has-children {
		   margin-bottom: 16px;
		}
	}

	li.menu-item.menu-item-has-children {
		margin-bottom: 23px;

		a {

		}
	}

	ul.sub-menu {
			list-style-type: none;
			padding: 0;
			padding-top: 21px;

	    li.menu-item a {
			border-bottom: none;
			font-weight: normal;
			display: block;
			color: $brand-purple;
			padding: 0 11px 13px;
		}
	}

	.textwidget {
		margin-top: 0;
		padding: 0 12px;

		p {
			font-size: 0.8em; // 16px;
			line-height: 1.875em; // 30px;
			margin: 0 0 8px;
		}
	}

	.footer-logos {
		text-align: center;
		padding-top: 20px;
		padding-bottom: 0;

		img {
			padding: 0 4px;
			width: 32%;
			max-width: 114px;
		}
	}

	hr {
		border-top: 3px solid #cacaca;
	   margin-bottom: 23px;
	   margin-top: 21px;
	}

	.copyright>ul {
		padding: 0;
		margin: 0;
		font-size: 0.8em;
		text-align:center;

		@media (min-width: $screen-md-min) {
			float: right;
			text-align:right;
		}

		>li {
			list-style: none;
			display:inline-block;

			a {
				color: $brand-purple;
				font-weight:bold;
			}

			@media (min-width: $screen-md-min) {
				//float: left;
				//display:block;
			}

			&+li:before {
				content: "|";
				padding: 0 3px;
			}
		}
	}

	.credits {
		font-size: 0.8em;
		clear: both;
	   padding-top: 12px;
		text-align: center;

		a {
			color: $brand-purple;
			font-weight:bold;
		}

		@media (min-width: $screen-md-min) {
			text-align: right;
			padding-top: 13px;
		}
	}

	.search-form {
		margin-bottom: 28px;

		.input-group {
			display:table;
		   margin: 0 auto;
		}

		.form-control {
			&.term {
				border: 0;
				border-bottom: 2px solid #ccc;
				font-size: 1.309em;
				box-shadow:none;
				height: 56px;
    			padding: 6px 18px;

				&:focus {
					border-color:  $brand-purple;
				}
			}
		}

		.btn-search {
			background: $brand-purple;
			color: #fff;
			font-size: 26.67px;
			border-bottom: 2px solid $brand-purple;
			padding: 10px 17px 10px 19px;
			height: 56px;
		}
	}

}

::-webkit-input-placeholder {
	font-style: italic;
}

:-moz-placeholder { /* Firefox 18- */
	font-style: italic;
}

::-moz-placeholder {  /* Firefox 19+ */
	font-style: italic;
}

:-ms-input-placeholder {
	font-style: italic;
}
